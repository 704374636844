import {ASSET_URL, FALLBACK_IMAGE} from '~/assets/js/constants'

export default {
  created() {
    this.url = useRuntimeConfig().public.ASSET_URL || ASSET_URL
  },
  methods: {
    getImageFromItem(item, isProduct) {
      let asset = null
      if (typeof item === 'object' && item !== null) {
        // item is Product and has property designSketch
        if (isProduct && item.designSketch && item.designSketch !== '') {
          asset = item.designSketch
          return asset
        }
        // item is Category and has property DesignSketch
        else if (item && item.DesignSketch) {
          asset = item.DesignSketch
          return asset
        }
        // item is Category and has property DesignSketchAssetURI
        else if (!isProduct && item.DesignSketchAssetURI && item.DesignSketchAssetURI !== '') {
          asset = item.DesignSketchAssetURI
          return asset
        }
      }
      // item is type string so we are looking for design in category
      else if (typeof item === 'string' && Array.isArray(this.categories)) {
        const category = this.categories.find(c => c.TechnicalName === item)
        if (category) {
          asset = category.DesignSketch || category.DesignSketchAssetURI
        }
      }
      //for new experimental search
      if (asset === null && typeof item === 'object' && Array.isArray(this.categories)) {
        const category = this.categories.find(c => c.TechnicalName === item.primaryL2Category)
        if (category) {
          asset = category.DesignSketch || category.DesignSketchAssetURI
        }
      }
      return asset
    },
    imageUrl(item, isProduct = false) {
      const asset = this.getImageFromItem(item, isProduct)
      return asset ? typeof asset === 'string' ? this.addAssetUrlPartIfNeeded(asset) : asset.url : FALLBACK_IMAGE
    },
    installationImageUrl(item, isProduct = false) {
      let asset = null
      if (isProduct) {
        if (item && item.designInstallationSketch && item.designInstallationSketch !== '') {
          asset = item.designInstallationSketch
        }
      }
      else {
        if (item && item.InstallationSketch) {
          asset = item.InstallationSketch
        }
        else if (item && item.InstallationSketchAssetURI && item.InstallationSketchAssetURI !== '') {
          asset = item.InstallationSketchAssetURI
        }
      }
      return asset ? typeof asset === 'string' ? this.addAssetUrlPartIfNeeded(asset) : asset.url : FALLBACK_IMAGE
    },
    addAssetUrlPartIfNeeded(asset) {
      if (asset.startsWith('http')) {
        return asset
      }
      return this.url + asset
    }
  }
}
